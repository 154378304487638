import React from 'react';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { graphql } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import Template from '../components/Template';
import Metadata from '../components/Metadata';
import BackgroundImage from '../components/BackgroundImage';
import { EN } from '../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      height: '100vh',
    },

    '& .MuiGrid-item': {
      textDecoration: 'none',

      [theme.breakpoints.up('md')]: {
        flex: 1,
        '&:hover': {
          '& $cardHead': {
            background: 'rgba(189, 189, 189, 0.85)',
            height: 330,
          },
          '& h5': {
            color: '#BC0F15',
          },
        },
      },

      [theme.breakpoints.down('sm')]: {
        height: '50vh',
        '&:not(:last-child)': {
          marginBottom: '5px',
        },
        '& $cardHead': {
          height: 130,
        },
      },
    },
  },

  cardRtl: {
    [theme.breakpoints.up('md')]: {
      '&:not(:nth-child(3))': {
        marginLeft: '5px',
      },
    },
  },

  cardLtr: {
    [theme.breakpoints.up('md')]: {
      '&:not(:nth-child(3))': {
        marginRight: '5px',
      },
    },
  },

  background: {
    height: '100%',
    minHeight: 'auto',
  },

  cardHead: {
    transition: 'all 0.5s ease-in-out',
    width: '70%',
    height: 300,
    background: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& h5': {
      color: 'white',
      [theme.breakpoints.up('xl')]: {
        fontSize: '2rem',
      },
    },
  },
}));

const Projects = ({ data }) => {
  const { t, i18n } = useTranslation();
  const currentLng = i18n.language;
  const classes = useStyles();
  const cardClass = currentLng === EN ? classes.cardLtr : classes.cardRtl;

  const Images = data.projects.edges;
  const Img1 = Images.filter((img) => img.node.base === 'molds.jpg');
  const Img2 = Images.filter((img) => img.node.base === 'parts.jpg');
  const Img3 = Images.filter((img) => img.node.base === 'contact.jpg');

  const sections = [
    { text: 'Menu.Projects.molds', route: '/projects/molds', img: Img1 },
    { text: 'Menu.Projects.parts', route: '/projects/parts', img: Img2 },
    {
      text: 'Menu.Projects.employers',
      route: '/projects/employers',
      img: Img3,
    },
  ];

  return (
    <Template>
      <Metadata pageTitle={t('Menu.Projects.text')} />
      <Grid container className={classes.root}>
        {sections.map((section) => (
          <Grid
            item
            container
            key={section.text}
            component={Link}
            to={section.route}
            className={cardClass}
          >
            <BackgroundImage
              bgImage={section.img[0].node.childImageSharp.gatsbyImageData}
              customClass={classes.background}
            >
              <Paper elevation={0} className={classes.cardHead}>
                <Typography variant="h5" align="center">
                  {t(section.text)}
                </Typography>
              </Paper>
            </BackgroundImage>
          </Grid>
        ))}
      </Grid>
    </Template>
  );
};

export default Projects;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    projects: allFile(filter: { relativeDirectory: { eq: "projects" } }) {
      edges {
        node {
          base
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
